import styled from '@emotion/styled';
import {graphql, Link, useStaticQuery} from 'gatsby';
import React from 'react';

import {rhythm, scale} from '../utils/typography';

interface Props {
  isBigHeader?: boolean;
}

interface LinkProps {
  to: string;
  children?: any;
}

const Header = ({isBigHeader}: Props) => {
  let header;
  const {site} = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `,
  );

  const StyledLink = ({to, children}: LinkProps) => (
    <Link
      style={{boxShadow: `none`, textDecoration: `none`, color: `inherit`}}
      to={to}
      date-id={'header-' + children.toString().toLowerCase()}
    >
      {children}
    </Link>
  );

  if (isBigHeader) {
    header = (
      <BigHeader>
        <StyledLink to={`/`}>{site.siteMetadata.title}</StyledLink>
      </BigHeader>
    );
  } else {
    header = (
      <SmallHeader>
        <StyledLink to={`/`}>{site.siteMetadata.title}</StyledLink>
      </SmallHeader>
    );
  }
  return header;
};

export default Header;

const BigHeader = styled.h1`
  ${scale(1.5)};
  margin-bottom: ${rhythm(3 / 2)};
  margin-top: ${rhythm(1 / 3)};
`;

const SmallHeader = styled.h3`
  font-family: Montserrat, sans-serif;
  margin-top: ${rhythm(2 / 3)};
`;
