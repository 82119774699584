import styled from '@emotion/styled';
import {Link} from 'gatsby';
import React from 'react';
import ThemeToggle from './toggle';

interface Props {
  to: string;
  children?: any;
}

const Navbar = () => {
  const ListLink = ({to, children}: Props) => (
    <Div>
      <Link to={to}>{children}</Link>
    </Div>
  );

  return (
    <Box>
      <ListLink to="/">Home</ListLink>
      <ListLink to="/about">About</ListLink>
      <ListLink to="/tags">Tags</ListLink>
      <ListLink to="/projects">Projects</ListLink>
      <div style={{marginLeft: 'auto'}}>
        <ThemeToggle />{' '}
      </div>
    </Box>
  );
};

export default Navbar;

const Box = styled.div`
  display: flex;
  align-items: stretch;
`;
const Div = styled.div`
  align-content: flex-start;
  margin-right: 1rem;
`;
