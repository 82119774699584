import styled from '@emotion/styled';
import React from 'react';

const Footer = () => {
  return (
    <footer style={{fontSize: `14px`}}>
      <Span>©</Span>️ {new Date().getFullYear()}, Built with ❤️ and appreciation using
      {` `}
      <a href="https://www.gatsbyjs.org" target="_blank">
        Gatsby
      </a>
      {` and `}
      <a href="https://www.typescriptlang.org/" target="_blank">
        Typescript
      </a>
    </footer>
  );
};

export default Footer;

const Span = styled.span`
  position: relative;
  top: 2px;
`;
