import {ThemeToggler} from 'gatsby-plugin-dark-mode';
import React from 'react';
import ToggleButton from 'react-toggle-button';

interface Props {
  theme?: string;
  toggleTheme?: any;
}

interface State {
  value: boolean;
}

class ThemeToggle extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {value: false};
  }
  render() {
    return (
      <ThemeToggler>
        {({theme, toggleTheme}: Props) => (
          <ToggleButton
            inactiveLabel={'dark'}
            activeLabel={'light'}
            colors={{
              activeThumb: {
                base: 'rgb(192,192,192)',
              },
              inactiveThumb: {
                base: 'rgb(192,192,192)',
              },
              active: {
                base: 'rgb(39,85,210)',
                hover: 'rgb(65,65,65)',
              },
              inactive: {
                base: 'rgb(73,108,206)',
                hover: 'rgb(95,96,98)',
              },
            }}
            value={this.state.value || false}
            onToggle={(value: boolean) => {
              this.setState({
                value: !value,
              });
              toggleTheme(this.state.value === false ? 'dark' : 'light');
            }}
          />
        )}
      </ThemeToggler>
    );
  }
}

export default ThemeToggle;
