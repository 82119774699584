import React from 'react';

import styled from '@emotion/styled';
import {rhythm} from '../utils/typography';
import Footer from './footer';
import Header from './header';
import Navbar from './navbar';

interface Props {
  isBigHeader?: boolean;
  children?: any;
}

const Layout = ({isBigHeader, children}: Props) => {
  return (
    <Div>
      <Navbar />
      <Header isBigHeader={isBigHeader} />
      <main>{children}</main>
      <Footer />
    </Div>
  );
};

export default Layout;

const Div = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: ${rhythm(30)};
  padding: ${rhythm(1.5)} ${rhythm(3 / 4)};
`;
